import { sentryConfig as defaultSentryConfig } from './Config.base';
export * from './Config.base';

export const sentryConfig = {
    ...defaultSentryConfig,
    environment: 'staging',
    debug: false
};

export const LookUpServerUrl = 'https://apistaging.visionslive.com/VL/focusGroupsRouter/getServerUri?token=';
export const growthbookApiKey = 'sdk-gHw1GSVo78ILrgPz';
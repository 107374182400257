/**
 * Attempts to load a favicon for the current hostname (which may be non-standard in the case of e.g. white-labelling)
 * If a favicon is found, then it replaces the default.
 */
const setFavicon = () => {
    const hostname = window.location.hostname;

    // This list is just used to cut down on 'favicon not found' console log errors'. If we are in one these domains,
    // we leave the favicon alone... i.e. it is hardcoded into index.html.
    const defaulHostnames = [
        'https://focusgroups.visionslive.com',
        'https://idi.visionslive.com'
    ];

    if (!defaulHostnames.includes(hostname)) {
        const faviconUrl = 'https://vlqrproductionstorage.blob.core.windows.net/whitelabelling/' + window.location.hostname + '/favicon.ico';

        fetch(faviconUrl, {
            //a "HEAD" request asks for headers only, no body
            method: 'HEAD',
            crossDomain: true
        }).then(res => {
            if (res.status === 200) {
                const favicon = document.getElementById('favicon');
                favicon.href = faviconUrl;
            } else {
                throw res.status;
            }
        }).catch(err => {
            console.log('Favicon NOT FOUND for domain: ' + hostname);
        });
    }
};

export default setFavicon;

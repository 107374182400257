import React from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { Box, styled } from '@mui/system';
import isChromatic from 'chromatic/isChromatic';

const VLLoadingSpinnerContainer = styled(Box)(({ theme }) => ({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'center',
    background: '#D9E2EC' // Hardcoded here to allow us to use this during initial load
}));

const FullScreenLoadingSpinner = () => {
    return (
        <VLLoadingSpinnerContainer>
            <GridLoader
                color={'#283C8E'} // Hardcoded here to allow us to use this during initial load
                size={35}
                loading
                // Disable animation on Chromatic, usual fixes for animation issues not working
                speedMultiplier={isChromatic() ? 0 : 1}
            />
        </VLLoadingSpinnerContainer>
    );
};

export default FullScreenLoadingSpinner;

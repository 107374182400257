import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { unregister } from './registerServiceWorker';
import { init as sentryInit } from '@sentry/react';
import { sentryConfig } from './Config';
import setFavicon from './setFavicon';
import FullScreenLoadingSpinner from 'Components/Auth/FullScreenLoadingSpinner';

sentryInit(sentryConfig);

const container = document.getElementById('root');
const root = createRoot(container);

const App = lazy(() => import('./Containers/App'));
const ApplicationLoader = () => {
    return <Suspense fallback={<FullScreenLoadingSpinner />}>
        <App />
    </Suspense>;
};
root.render(<ApplicationLoader />);

const loadAndStartStore = async () => {
    const { startApp } = await import('./Store/store');
    startApp();
};

loadAndStartStore();
setFavicon();
unregister();
